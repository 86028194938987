<template>
	<div class="px-2 sm:px-10 py-4">
    <div class="flex space-x-5 items-start">
      <div class="w-20 h-20 bg-gray-200"></div>
      <div class="flex space-x-5 items-center">
        <Text color="black" weight="bold" size="xl" class="sm:text-3xl" :content="'Startup Virginia'"/>
        <Text size="sm" color="primary-600" content="edit" decoration="underline" custom-class="cursor-pointer"/>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
      <CardWithIconAndButton v-for="card in content.cards" :key="card.category" v-bind="card"/>
    </div>
    <div class="border px-6 py-4 bg-white mt-5">
      <Text size="sm" weight="semibold" color="black" content="Invite founders Startup Virginia!"/>
      <Text custom-class="mt-4" size="xs" color="black" content="You can email this link to people that you'd like to join so that you help them through each step with their idea and track their progress."/>
      <div class="mt-4">
        <Text size="xs" weight="semibold" color="black" content="Registration Link"/>
        <div class="border rounded-md border-gray-300 relative px-3 py-2 flex justify-between items-center mt-1">
          <input class="border-none outline-none focus:ring-0 focus:border-none focus:outline-none w-full"/>
          <Icon name="DocumentDuplicateIcon" size="6" color="gray-500"/>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <ListViewHeader :title="'Current Cohort'"/>
      <div class="space-y-3 mt-5">
        <CardWithBadge v-for="cohort in content.cohorts" :key="cohort.id" v-bind="cohort"/>
      </div>
    </div>
    <div class="mt-10">
      <ListViewHeader :title="'Programs (2)'" :btn-text="'Add'"/>
      <div class="space-y-3 mt-5">
        <CardWithBadge v-for="cohort in content.programs" :key="cohort.id" v-bind="cohort"/>
      </div>
    </div>
	</div>
</template>

<script>
import EmptyState from '../components/organisms/EmptyState/Range/Range'
import ListViewHeader from '../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton'
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Table from '../components/organisms/Tables/Simple/Simple.vue'
import Text from '../components/atoms/Text/Text';
import { c } from '../components/constants.js';
import InviteMember from '../components/organisms/Modals/InviteMember/InviteMember.vue'
import Icon from '../components/atoms/Icons/Icons'
import CardWithIconAndButton from "../components/molecules/CardListItem/CardWithIconAndButton/CardWithIconAndButton";
import { get } from 'lodash'

export default {
	components: {
		EmptyState, ListViewHeader, CardWithImage, CardWithBadge, Text, InviteMember, Table, Icon, CardWithIconAndButton
	},
	data() {
		return {
			content: c.ecosystem,
			isShow: false,
			isOpenTip: true,
			isLoading: false,
			get,
			c,
			venture: null,
		}
	},
	methods: {
	},
}
</script>
